const SideBarConfig = {
  ADMIN: [
    {
      name: 'Companies',
      url: '/companies',
    },
    {
      name: 'Requirements',
      url: '/requirements',
    },
    {
      name: 'Purchase Order',
      url: '/purchase-order',
    },
    {
      name: 'Vendor Expense',
      url: '/expenses',
    },
    {
      name: 'Invoices',
      url: '/invoices',
    },
    {
      name: 'Bulk Uploads',
      url: '/bulk-uploads',
    },
    {
      name: 'Credit Notes',
      url: '/credit-note',
    },
    {
      name: 'Reports',
      url: '/reports',
    },
    {
      name: 'Users',
      url: '/users',
    },
    {
      name: 'GST',
      url: '/gstNumbers',
    },
  ],
  KAM: [
    // {
    //   name: "Dashboard",
    //   url: "/dashboard",
    // },
    {
      name: 'Companies',
      url: '/companies',
    },
    {
      name: 'Requirements',
      url: '/requirements',
    },
    {
      name: 'Purchase Order',
      url: '/purchase-order',
    },
    {
      name: 'Vendor Expense',
      url: '/expenses',
    },
    {
      name: 'Invoices',
      url: '/invoices',
    },
  ],
  GROUND_TEAM: [
    {
      name: 'Requirements',
      url: '/requirements',
    },
    {
      name: 'Purchase Order',
      url: '/purchase-order',
    },
  ],
  BILLER: [
    {
      name: 'Requirements',
      url: '/requirements',
    },
    {
      name: 'Invoices',
      url: '/invoices',
    },
    {
      name: 'Credit Notes',
      url: '/credit-note',
    },
    {
      name: 'Reports',
      url: '/reports',
    },
    {
      name: 'GST',
      url: '/gstNumbers',
    },
  ],
  PAYER: [
    {
      name: 'Companies',
      url: '/companies',
    },
    {
      name: 'Requirements',
      url: '/requirements',
    },
    {
      name: 'Purchase Order',
      url: '/purchase-order',
    },
    {
      name: 'Vendor Expense',
      url: '/expenses',
    },
    {
      name: 'Invoices',
      url: '/invoices',
    },
    {
      name: 'Bulk Uploads',
      url: '/bulk-uploads',
    },
    {
      name: 'Credit Notes',
      url: '/credit-note',
    },
    {
      name: 'Reports',
      url: '/reports',
    },
    {
      name: 'Users',
      url: '/users',
    },
    {
      name: 'GST',
      url: '/gstNumbers',
    },
  ],
  MAKER: [
    {
      name: 'Requirements',
      url: '/requirements',
    },
    {
      name: 'Purchase Order',
      url: '/purchase-order',
    },
    {
      name: 'Vendor Expense',
      url: '/expenses',
    },
  ],
};

const getSidebarConfig = (role) => {
  if (SideBarConfig[role]) {
    return SideBarConfig[role];
  }
  return SideBarConfig.KAM;
};

export { getSidebarConfig };
